import * as React from 'react';
import { graphql } from 'gatsby';
import { Box, Text, Flex } from '@chakra-ui/react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { AiFillPlayCircle } from 'react-icons/ai';
import Modal from 'react-modal';
import ReactPlayer from 'react-player';

import SeoComponent from '../components/SeoComponent';
import LinkWrapper from '../components/LinkWrapper';
import { FeaturedContentPress } from '../sections/FeaturedContentPress';
import SectionsBlogPostSelector from '../sections/SectionsBlogPostSelector';

/* IMPORT APP CONTEXT */
import { AppContext } from '../context';

/* MODAL SETUP */
const customStyles = {
  overlay: {
    zIndex: 9999,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
  content: {
    padding: 0,
    border: 'none',
    borderRadius: 0,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

Modal.setAppElement('#___gatsby');

function PressPostTemplate(props) {
  const { pageContext, data } = props;
  const { mainImage, section, seoDescription, seoTitle, title, iframe, publishedAt } = pageContext.pressPost;
  const appContext = React.useContext(AppContext);
  const seoImg = data.pressPost.seoImage?.image || data.pressPost?.mainImage;

  const pressCategories = {
    read: 'read',
    watch: 'watch',
    listen: 'listen', 
  };

  const formatDate = () => {
    const dateObj = new Date(publishedAt)
    const monthNameLong = dateObj?.toLocaleString("en-US", { month: "long" });
    return `${monthNameLong} ${publishedAt?.slice(-2)}, ${publishedAt?.slice(0,4)}`
  }

  /* VERIFY CATEGORY, SUBCATEGORY AND MEDIA TYPE */
  const [category, setcategory] = React.useState(null);
  const [pressRoute, setPressRoute] = React.useState(null);

  React.useEffect(() => {
    let currentCategory = '';

    if (iframe && iframe.startsWith('http')) {
      currentCategory = pressCategories.watch;

    } else if (iframe && !iframe.startsWith('http')) {
      currentCategory = pressCategories.listen;

    } else {
      currentCategory = pressCategories.read;
    }
    setcategory(currentCategory);
    const pressCategory = data.allSanityPressCategory.nodes;
    setPressRoute(pressCategory[0].pressRoute.route);
  }, []);

  /* MODAL CONTROL */
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [videoURL, setVideoURL] = React.useState(null);

  const openModal = () => {
    const url = category === pressCategories.watch ? iframe : '';
    setVideoURL(url);
    setModalIsOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    setModalIsOpen(false);
    document.body.style.overflow = 'auto';
  };

  return (
    <>
      <SeoComponent
        title={seoTitle}
        description={seoDescription}
        imageUrl={seoImg?.asset?.url}
      />

      <Box
        w="100%"
        h={{base:'auto'}}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        position="relative"
      >
        {/* GRAY BOX */}
        <Box
          w="100%"
          h="496px"
          bgColor="grayBG"
          position="absolute"
          top="0"
          style={{zIndex:0}}
        >
          {/* SVG BOTTOM IMAGE */}
          <SvgCurve top={false} />
        </Box>

        {/* MAIN IMAGE AND TITLE */}
        <Flex
          direction="column"
          mt={{base:'70px', md:'70px'}}
          style={{zIndex:2}}
          justifyContent="center"
          alignItems="center"
          mb={{base:'73px', ms:'58px', msx:'43px', md:'69px', mds:'5px', lg:'51px', lgs:'35px', xl:'27px'}}
        >
          {/* BACK TO WANA CATEGORY */}
          {pressRoute && (
            <LinkWrapper href={pressRoute || '/'}>
              <Text
                w="max-content"
                color='primary'
                fontSize={{base:'16px', md:'16px'}}
                fontWeight="800"
                lineHeight={{base:'20px', md:'20px'}}
                letterSpacing={{base:'4.8px', md:'4.8px'}}
                textAlign="center"
                textTransform="uppercase"
                borderBottom="1px"
                borderColor="transparent"
                _hover={{borderBottom:'1px', borderColor:'#272158'}}
                mb={{base:'15px', md:'15px'}}
              >
                {'< press releases'}
              </Text>
            </LinkWrapper>
          )}

          {/* TITLE */}
          <Text
            maxW={{base:'93vw', mds:'857px', lg:'950px', lgs:'950px', xl:'950px'}}
            color='primary'
            fontSize={{base:'25px', ms:'30px', msx:'30px', md:'30px', mds:'35px', lg:'35px', lgs:'35px'}}
            fontWeight={{base:'800', md:'800'}}
            lineHeight={{base:'34px', ms:'39px', msx:'44px', md:'49px', mds:'54px', lg:'58px', lgm:'62px', lgs:'66px', xl:'70px'}}
            letterSpacing={{base:'-0.22px', md:'-0.39px'}}
            textTransform="uppercase"
            textAlign="center"
            mb={{base:'42px', md:'46px'}}
            mt={{base:'42px', md:'46px'}}
          >
            {title}
          </Text>

          {/* MAIN IMAGE / COVER */}
           {mainImage || category === 'watch' || category === 'listen' ?
              <Flex
                maxW={{base:'90vw', ssm:'330px', ms:'354px', msx:'54.1vw', md:'54vw', lg:'54vw', xl:'778px'}}
                h={{base:'211px', ms:'225px', msx:'34.38vw', md:'34.38vw', lg:'34.38vw', xl:'495px'}}
                position="relative"
              >
                {mainImage && (
                  <GatsbyImage image={mainImage?.asset?.gatsbyImageData} alt={'wana press cover image'} />
                )}

            {/* PLAY BUTTON IF MEDIA IS A VIDEO */}
              {category === 'watch' && (
                <Box
                  w="100%"
                  h="100%"
                  position="absolute"
                  top="0"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  {/* ICON */}
                  <Box
                    w="auto"
                    h="auto"
                    borderRadius="full"
                    cursor='pointer'
                    style={{zIndex:2}}
                    onClick={openModal}
                  >
                    <AiFillPlayCircle style={{ fontSize: '92px', color: '#272158' }} />
                  </Box>

                  {/* ICON WHITE BACKGROUND */}
                  <Box
                    w="46px"
                    h="46px"
                    bgColor="white"
                    borderRadius="full"
                    position="absolute"
                  />
                </Box>
              )}
                        {/* AUDIO IFRAME */}
          {category === 'listen' && (
            <Box
              w={{base:'330px', ms:'354px', msx:'54.1vw', md:'54vw', lg:'54vw', xl:'778px'}}
              h="auto"
              display="flex"
              justifyContent="center"
              alignItems="flex-end"
              mt="10px"
            >
          {/* PODCST IFRAME */}
            <Flex
                className="audio-iframe"
                w="100%"
                maxH="auto"
                border="1px"
                borderColor="gray.400"
                dangerouslySetInnerHTML={{
                  __html: `${iframe}`,
                }}
              />
            </Box>
          )}
              </Flex> 
              :
            null}
        </Flex>

        {/* DATE */}
        <Box
          w={{base:'87%', ms:'87%', msx:'87%', md:'87%', lg:'84%', xl:'1200px'}}
          mt="125px"
        >
          <Flex
            //Adjust here in case client require less blank space before date.
            mt={{base:'68px', ssm:'68px', ms:'78px', msx:'88px', md:'108px', lg:'58px', lgs:'70px', xl:'70px'}} 
            className='formatDate'
          >
            {formatDate()}
          </Flex>
        </Box> 
        
        {/* PRESS SECTIONS */}
        {section && (
          <Flex
            direction="column"
            maxW={{base:'87%', ms:'87%', msx:'87%', md:'87%', lg:'84%', xl:'1200px'}}
            mx="auto"
            zIndex="1"
            alignItems="center"
          >
            {section.map((pressSection, indx) => (
              <SectionsBlogPostSelector
                key={`one-section-${pressSection?._key}-${indx}`}
                data={pressSection}
              />
            ))}
          </Flex>
        )}

        {/* CONTENT */}
        {category && (
          <FeaturedContentPress
            mt={{base:'20px', md:'20px'}}
            wanaKnowMore
            currentPressTitle={title}
          />
        )}
      </Box>

      {/* MODAL */}
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel='watch modal'>
        <Box className='player-wrapper'>
          <ReactPlayer className='react-player' width='100%' height='100%' playing={modalIsOpen} auto url={videoURL} controls={true}/>
        </Box>
      </Modal>
    </>
  );
}

const SvgCurve = ({ top }) => {
  const position = top ? {top:'0'} : {bottom:'0'};

  return (
    <Box
      w="100%"
      h={top ? {base:'2%', ms:'3%', md:'5%', mds:'10%', lg:'15%'} : {base:'30%', md:'25%', lg:'30%', xl:'30%', xxl:'31%', sl:'32%', ssl:'40%'}}
      position="absolute"
      left="0"
      style={{zIndex:0, ...position}}
    >
      {/* TOP SVG */}
      {top && (
        <svg viewBox="0 0 500 149" preserveAspectRatio="none" style={{width:'100%', height:'100%'}}>
          <path
            fill="white"
            d="M-0.27,140.63 C250.27,141.63 310.10,-85.34 500.27,106.09 L500.00,0.00 L0.00,0.00 Z"
          >
          </path>
        </svg>
      )}

      {/* BOTTOM SVG */}
      {!top && (
        <svg viewBox="0 0 500 149" preserveAspectRatio="none" style={{width:'100%', height:'100%'}}>
          <path
            fill="white"
            d="M-0.27,73.53 C192.15,3.48 325.90,143.59 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
          >
          </path>
        </svg>
      )}
    </Box>
  );
};

export const query = graphql`
  query PRESS_POST_QUERY ($id: String!) {

    allSanityPressCategory {
      nodes {
        slug {
          current
        }
        pressRoute {
          route
        }
        pressRoute {
          route
        }
      }
    }
    readPosts: allSanityPressPost(
      filter: {iframe: {eq: null}, isPublic: {eq: true}}
      sort: {fields: publishedAt, order: DESC}
      ) {
      nodes {
        isPublic
        title
        iframe
        slug {
          current
        }
        mainImage {
          asset {
            gatsbyImageData(width: 570, height: 405, fit: MAX, placeholder: NONE)
          }
        }
        resume
        publishedAt
      }
    }
    mediaPosts: allSanityPressPost(
      filter: {iframe: {regex: "/^http/"}, isPublic: {eq: true}}
      sort: {fields: publishedAt, order: DESC}
      ) {
      nodes {
        isPublic
        title
        iframe
        slug {
          current
        }
        mainImage {
          asset {
            gatsbyImageData(width: 570, height: 405, fit: MAX, placeholder: NONE)
          }
        }
        resume
        publishedAt
      }
    }
    pressPost: sanityPressPost (id: {eq: $id }) {
      seoImage {
        image {
          asset {
            url
          }
        }
      }
      mainImage {
        asset {
          url
        }
      }
    }

  }
`;

export default PressPostTemplate;

