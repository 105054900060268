import * as React from 'react';
import { Box, Text } from '@chakra-ui/react';
import LinkWrapper from '../LinkWrapper';

function WanaPressContentCard(props) {
  const { item, route } = props;
  const date = item?.publishedAt?.slice(0,10)

  const formatDate = () => {
    const dateObj = new Date(date)
    const monthNameLong = dateObj?.toLocaleString("en-US", { month: "long" });
    return `${monthNameLong} ${date?.slice(-2)}, ${date?.slice(0,4)}`
  }

  return (
    <Box
      w="max-content"
      h="max-content"
      mx={'0'}
      className="fadeIn"
    >
      <LinkWrapper href={`${route}${item?.slug?.current}`}>  
        <Box
          w={{base:'84vw', ssm:'310px', ms:'340px', msx:'55vw', md:'42.4vw', mds:'39vw', lg:'34.4vw', lgs:'26.4vw', xl:'389px'}}
          h={{base:'300px'}}
          mb={{base:'30px', msx:'60px'}}
          mr={{ lg:'49px', lgs:'40px', xl:'21px' }}
        >
          <Box
            maxW="350px"
            minH="300px"
            overflow="hidden"
            className={'wana-content-card-home'}
            bgColor="grayBG"
            p={{base:'34px 4% 29px 5%'}}
            position={'relative'}
          >
            <Text
              color="#7FC344"
              textTransform={'uppercase'}
              fontSize={{base:'14px'}}
              fontWeight={{base:'700', md:'700'}}
              lineHeight={{base:'1.5'}}
              letterSpacing={{base:'1.2px', md:'1.2px'}}
              textAlign={{base:'left'}}
              mb={{base:'17px'}}
            >
              {formatDate()}
            </Text>
            <Text
              mb={{base:'15px', md:'25px'}}
              border={'1px'}
              color="#FFFFFF"
            >
            </Text>
            <Text
              color="primary"
              fontSize={{base:'18px'}}
              fontWeight={{base:'700', md:'700'}}
              lineHeight={{base:'1.9'}}
              letterSpacing={{base:'-0.24px', md:'-0.24px'}}
              textAlign={{base:'left'}}
              noOfLines={{base:4}}
              p={{base:'0px 14.5% 0px 2.5%'}}
            >
              { item.title === null ? "Building information..." : item.title }
            </Text>
            <Text
              color="primary"
              textTransform={'uppercase'}
              fontSize={{base:'13px'}}
              fontWeight={{base:'900', md:'900'}}
              lineHeight={{base:'1.5'}}
              letterSpacing={{base:'1.2'}}
              textAlign={{base:'left'}}
              position={'absolute'}
              top={'257px'}
            >
              {'learn more'}
            </Text>
          </Box>

          {/* TEXT */}
          <Box
            minH={{base:'182px'}}
            w={{base:'50px'}}
            bgColor="primary"
            p={{base:'15px', ms:'15px', msx:'10px 5px', md:'10px 5px', mds:'15px 10px', lg:'21px 14px', lgs:'23px 16px', xl:'25px 18px'}}
            overflowY="hidden"
            position={'relative'}
            left={'-14px'}
            top={'-242px'}
            zIndex={'-1'}
          >
          </Box>
        </Box>
      </LinkWrapper>
    </Box>
  );
}

export default WanaPressContentCard;
