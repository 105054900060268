import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Box, Text, Flex, Heading } from '@chakra-ui/react';
import LinkWrapper from '../../components/LinkWrapper';
import WanaPressContentCard from '../../components/WanaPressContentCard';

/* IMPORT APP CONTEXT */
import { AppContext } from '../../context';

/* THIS COMPONENT SHOULD BE UPDATED WHEN WE HAVE THE LEARN SCHEMAS IN SANITY */
function FeaturedContentPress(props) {
  const { wanaKnowMore, mt, currentPressTitle } = props;
  
  /* QUERY */
  const data = useStaticQuery(graphql`
    query {
      allSanityPressPost(sort: {fields: publishedAt, order: DESC}, limit: 4) {
        nodes {
          title
          iframe
          publishedAt
          resume
          slug {
            current
          }
        }
      }
      allSanityPressCategory {
        nodes {
          tag
          tagColor {
            color
          }
          pressRoute {
            route
          }
        }
      }
    }
  `);

  const appContext = React.useContext(AppContext);

  /* BLOG DATA TO USE */
  const [pressToDisplay, setPressToDisplay] = React.useState(null);
  const morePressReleases = data.allSanityPressPost.nodes.length

  React.useEffect(() => {
    let press = [];
    press = data?.allSanityPressPost?.nodes?.filter(item => item.title !== currentPressTitle);
    setPressToDisplay(press);
  }, []);

  if( morePressReleases > 1 ) {
    return (
      <Box 
        w="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        position='relative' 
        mt={mt || {base:'180px', ms:'160px', msx:'14.5vw', md:'13.2vw', xl:'190px'}} 
        bgColor="white"
        pb={{base:'7.64vw', ms:'7.64vw', md:'110px'}}
      >
        {/* SVG TOP IMAGE */}
        <SvgCurve top />
  
        {/* CONTENT */}
        <Flex
          w={{base:'95%', ms:'100%', md:'87vw', mds:'87vw', lg:'84vw', xl:'1200px'}} 
          direction="column"
        >
          {/* WANA KNOW MORE HEADING */}
          <Heading
            display={wanaKnowMore ? 'block' : 'none'}
            color={'primary'}
            fontFamily="Outfit"
            fontSize={{base:'44px', ms:'47px', msx:'50px', md:'53px', mds:'56px', lg:'59px', lgs:'62px', xl:'65px'}}
            fontWeight="800"
            letterSpacing={{base:'-0.5px'}}
            lineHeight={{base:'1'}}
            textAlign="center"
            pt={{base:'16vw', ms:'15vw', msx:'13vw', md:'12vw', mds:'11.5vw', lg:'11vw', lgs:'10.77vw', xl:'155px'}}
            mb={{base:'5vw', ms:'5vw', msx:'4vw', md:'3.75vw', mds:'3.75vw', lg:'3.75vw', lgs:'3.75vw', xl:'54px'}}
          >
            {'More news'}
          </Heading>
  
          {/* CONTENT ITEMS */}
          <Flex
            w={{base:'100%', msx:'100%', md:'100%', mds:'100%', lg:'84%', lgs:'73%', xl:'1231px'}}
            mx="auto"
            mt={{base:'5.14vw', xl:'74px'}}
            direction={{base:'column', md:'column', lg:'row'}}
            justifyContent={{base:'center', lgs:'space-between'}}
            flexWrap={{base:'wrap'}}
            alignItems={{base:'center'}}
          >
            {pressToDisplay && (
              <>
                {pressToDisplay.slice(0,3).map((item, indx) => {
                  return (
                    <React.Fragment key={indx}>
                      {item.title !== currentPressTitle && (
                        <WanaPressContentCard 
                          item={item} 
                          route={data.allSanityPressCategory.nodes[0].pressRoute.route}
                        />
                      )}
                    </React.Fragment>
                  );
                })}
              </>
            )}
          </Flex>
        </Flex>
  
        {/* SVG BOTTOM IMAGE */}
        <SvgCurve top={false} />
      </Box>
    );
  }else {
    return <></>
  }
}

const SvgCurve = ({ top }) => {
  const position = top ? {top:'-5px'} : {bottom:'-5px'};

  return (
    <Box
      w="100%"
      h={top ? {base:'3%', ms:'4%', msx:'8%', md:'8%', mds:'8%', lg:'8%', lgs:'8%', xl:'8%'} : {base:'2%', md:'10%'}}
      position="absolute"
      left="0"
      style={{zIndex:0, ...position}}
    >
      {/* TOP SVG */}
      {top && (
        <svg viewBox="0 0 500 149" preserveAspectRatio="none" style={{width:'100%', height:'100%'}}>
          <path
            fill="white"
            d="M-0.27,140.63 C250.27,141.63 310.10,-85.34 500.27,106.09 L500.00,0.00 L0.00,0.00 Z"
          >
          </path>
        </svg> 
      )}

      {/* BOTTOM SVG */}
      {!top && (
        <svg viewBox="0 0 500 149" preserveAspectRatio="none" style={{width:'100%', height:'100%'}}>
          <path
            fill="white"
            d="M0.00,49.98 C149.99,150.00 330.98,145.56 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
          >
          </path>
        </svg> 
      )}
    </Box>
  );
};

const ContentItem = ({ item }) => {
  return (
    <LinkWrapper 
      href={`${item.slug.current}` || '/'}
    >
      <Box
        w={{base:'298px', ms:'288px', msx:'298px', md:'298px', mds:'42.9vw', lg:'41vw', lgs:'41vw', xl:'585px'}}
        mb={{base:'20px', ms:'22px', md:'3.25vw', mds:'1.12vw', lg:'1.95vw', lgs:'2vw', xl:'30px'}}
        display='flex'
        flexDirection={{base:'column', mds:'row'}}
      >
        {/* TEXT CONTENT */}
        <Flex
          w="100%"
          maxH={{base:'210px', md:'210px', mds:'18.4vw', xl:'265px'}}
          minH={{base:'210px', md:'210px', mds:'auto'}}
          direction="column"
          alignItems={{base:'center', mds:'flex-start'}}
          pt={{base:'32px', mds:'2vw', lg:'2.7vw', lgs:'3.3vw', xl:'53px'}}
          pl={{mds:'2vw', lg:'2.5vw', lgs:'3.1vw', xl:'50px'}}
          pb={{base:'25px', mds:'0', lg:'0'}}
          bgColor="primaryDarker"
          overflow="hidden"
        >
          {/* TITLE */}
          <Text
            w={{base:'280px', md:'280px', mds:'27.5vw', lg:'25vw', lgs:'23vw', xl:'330px'}}
            h="auto"
            color="white"
            fontSize={{base:'25px'}}
            fontWeight='bold'
            letterSpacing={{base:'0'}}
            lineHeight={{base:'40px'}}
            textAlign={{base:'center', mds:'left'}}
            noOfLines={3}
            py={{base:'15px', mds:'0'}}
            mt={{base:'0', mds:'1vw', lg:'1.3vw', xl:'18px'}}
          >
            {item?.title} 
          </Text>
        </Flex>
      </Box>
    </LinkWrapper>
  );
};

export { FeaturedContentPress, ContentItem, SvgCurve };
